import ReactGA from 'react-ga4'
import { Link, useLocation } from 'react-router-dom'
import { FileTextOutlined, PhoneOutlined } from '@ant-design/icons'
import { useConfig } from '@unholster/react-use-config'
import { Layout, Menu } from 'antd'
import styled from 'styled-components'

import { AppLogo, PoweredUnholster } from 'assets'
import { colors } from 'Constants'

const { Content, Footer } = Layout

export default function SideBar() {
  const { pathname } = useLocation()
  const { flags = {} } = useConfig()
  const currentKey = pathname === '/' ? '/llamadas' : pathname
  ReactGA.send({ hitType: 'pageview', page: pathname })

  const menuItems = [
    {
      icon: <StyledPhone />,
      key: '/llamadas',
      route: '/llamadas',
      title: 'Audios',
    },
    {
      hide: !flags.ReportUpload,
      icon: <FileTextOutlined />,
      key: '/reportes',
      route: '/reportes',
      title: 'Reportes',
    },
  ]

  return (
    <StyledLayout>
      <Content>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <StyledMenu
          defaultSelectedKeys={[currentKey]}
          items={menuItems
            .filter((item) => !item.hide)
            .map(({
              icon, key, route, title,
            }) => ({
              icon,
              key,
              label: (
                <Link className="menu-link" to={route}>
                  {title}
                </Link>
              ),
            }))}
        />
      </Content>
      <Footer style={{ paddingLeft: '0px' }}>
        <UnholsterFooter />
      </Footer>
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  height: 100%;
`

const LogoContainer = styled.div`
  padding: 20px 0px 15px 0px;
  display: flex;
  justify-content: center;
`

const StyledMenu = styled(Menu)`
  .ant-menu-item {
    padding-left: 21px !important;
    border-radius: 0 10px 10px 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: 600;
  }

  .anticon,
  .menu-link {
    color: ${colors.menuUnselectedItem};
  }

  .ant-menu-item-active {
    background-color: ${colors.menuActiveItemBackground};
    color: ${colors.menuActiveItem};
    .menu-link {
      color: ${colors.menuActiveItem};
    }
  }

  .ant-menu-item-selected {
    background-color: ${colors.menuSelectedItemBackground} !important;
    color: ${colors.menuSelectedItem} !important;
    .ant-menu-item-icon {
      color: ${colors.menuSelectedItem};
    }
    path {
      stroke-width: 1.3;
      stroke: ${colors.scannerBlue} !important;
    }
  }
  .custom-icon {
    svg {
      color: #fff;
    }
    path {
      stroke-width: 1.3;
      stroke: ${colors.menuUnselectedItem};
    }
  }
`

const UnholsterFooter = styled(PoweredUnholster)`
  display: block;
  margin-left: auto;
  width: 70%;
`

const StyledPhone = styled(PhoneOutlined)`
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
`
