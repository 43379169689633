import { baseUrl } from 'config'
import { useAuth } from 'vendor/authkit'

export default function Logout() {
  const { setAuthenticated } = useAuth()

  const handleLogout = () => {
    setAuthenticated(false)
    window.location.href = `${baseUrl}/login`
  }

  fetch(`${baseUrl}/auth/logout`, {
    method: 'GET',
    mode: 'no-cors',
  }).then(handleLogout)
    .catch(handleLogout)

  return null
}
