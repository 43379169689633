export default function OptionalOutlink({ children, href }) {
  if (!href) return null
  return (
    <a
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}
