import { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'

export default function Tag({
  className, color, name, onClose,
}) {
  const [hover, setHover] = useState(false)

  return (
    <StyledContainer
      className={className}
      color={color}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <StyledTextContainer color={color}>
        <StyledText>{name}</StyledText>
      </StyledTextContainer>
      {hover && onClose && (
        <StyledCloseButton color={color} onClick={onClose} />
      )}
    </StyledContainer>
  )
}

export function TagsContainer({ onClose, tags }) {
  return (
    <StyledTagsContainer>
      {tags.map((tag) => (
        <Tag
          key={tag.id}
          name={tag.nombre}
          color={tag.color}
          onClose={onClose ? () => onClose(tag.id) : null}
        />
      ))}
    </StyledTagsContainer>
  )
}

const StyledTagsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
`

const StyledContainer = styled.div`
  display: flex;
  background-color: ${(props) => hexToRgba(props.color, 0.2)};
  border-radius: 10px;
  font-size: 10px;
  padding: 0 10px;
`

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  margin: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`

const StyledText = styled.span`
  font-size: 13px;
  line-height: 24px;
`

const StyledCloseButton = styled(CloseOutlined)`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  font-size: 8px;
  padding-left: 4px;
`
