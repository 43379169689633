import { Modal } from 'antd'

import Comparator from './Comparator'

export default function ComparatorModal({
  closeModal,
  comparedArticulos,
  highlightedContent,
  nombre,
  visible,
}) {
  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={closeModal}
      onOk={closeModal}
      visible={visible}
      width={1000}
    >
      <Comparator
        comparedArticulos={comparedArticulos}
        highlightedContent={highlightedContent}
        nombre={nombre}
      />
    </Modal>
  )
}
