import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

import AvatarConvencional from 'components/AvatarConvencional'
import { colors } from 'Constants'

const INTERVALS = [3600, 60, 1]

// SCROLL_OFFSET: Distance from top of window to scroll to.
// Should be calculated from DOM metrics, hardcoded for now
const SCROLL_OFFSET = 540

export const toSeconds = (timecode) => timecode
  .split(':')
  .map((x, i) => Number.parseInt(x, 10) * INTERVALS[i])
  .reduce((a, b) => a + b)

const getSpeakersColors = (intervenciones) => {
  let currentColorIndex = -1
  return intervenciones.reduce((acc, intervencion) => {
    if (Object.keys(acc).includes(intervencion.hablante)) {
      return acc
    }
    currentColorIndex = (currentColorIndex + 1) % colors.speakers.length
    return {
      ...acc,
      [intervencion.hablante]: colors.speakers[currentColorIndex],
    }
  }, {})
}

const Transcripcion = forwardRef((
  {
    highlightSeconds,
    onSeek,
    transcripcion,
  },
  ref,
) => {
  const [following, setFollowing] = useState(true)
  const highlightedIdx = transcripcion.map((_, idx) => idx).filter(
    (idx) => (idx < transcripcion.length)
      && (highlightSeconds >= toSeconds(transcripcion[idx].tiempo_inicio))
      && (highlightSeconds < toSeconds(transcripcion[idx].tiempo_fin)),
  )[0]

  const speakerColors = getSpeakersColors(transcripcion)

  useImperativeHandle(ref, () => ({
    follow: () => {
      setFollowing(true)
    },
  }))

  return (
    <StyledDiv>
      <StyledHeader>
        <StyledHeaderSpeaker>Hablante</StyledHeaderSpeaker>
        <StyledHeaderTime>Tiempo</StyledHeaderTime>
        <StyledHeaderText>Transcripción</StyledHeaderText>
      </StyledHeader>
      { transcripcion.map((item, idx) => (
        <StyledSegmento
          color={speakerColors[item.hablante]}
          following={following}
          highlighted={idx === highlightedIdx}
          key={`${item.hablante}-${item.tiempo_inicio}`}
          onClick={() => onSeek(toSeconds(item.tiempo_inicio))}
          hablante={item.hablante}
          tiempoInicio={item.tiempo_inicio}
          texto={item.texto}
        />
      ))}
    </StyledDiv>
  )
})

function Segmento({
  className,
  color,
  following = false,
  hablante,
  highlighted,
  onClick,
  texto,
  tiempoInicio,
}) {
  const ref = useRef()

  useEffect(
    () => {
      if (ref.current && highlighted && following) {
        ref.current.scrollIntoView()
      }
    },
    [highlighted, following],
  )

  return (
    <Container className={`${highlighted ? 'highlighted' : ''} ${className}`} ref={ref}>
      <div><StyledAvatar color={color} size={19} /></div>
      <StyledSpeaker>{hablante}</StyledSpeaker>
      <StyledTime>
        <StyledButton
          type="link"
          onClick={onClick}
        >
          {tiempoInicio}
        </StyledButton>
      </StyledTime>
      <StyledText
        className="texto"
      >
        {texto}
      </StyledText>
    </Container>
  )
}

const StyledDiv = styled.div`
  margin-top: 16px;
`

const StyledHeader = styled.div`
  display: flex;
  background: #FAFAFA;
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`

const StyledHeaderSpeaker = styled.div`
  margin-left: 30px;
`

const StyledHeaderTime = styled.div`
  margin-left: 170px;
`

const StyledHeaderText = styled.div`
  margin-left: 80px;
`

const Container = styled.div`
  display: grid;
  gap: 24px;
  grid-template: auto / 10px 180px 111px auto;

  border: 2px solid transparent;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #333333;

  padding-left: 32px;

  scroll-margin-top: ${SCROLL_OFFSET}px;

  &.highlighted .texto{
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.4px;
    color: #000000;
  }

  div {
    padding: 8px;
  }
`

const LittleContainer = styled.div`
  margin-top: 13px !important;
`

const StyledSpeaker = styled(LittleContainer)`
  color: #000000;
  font-weight: 700;
`
const StyledTime = styled(LittleContainer)`
  padding: 0px;
`

const StyledButton = styled(Button)`
  align-items: left;
  display: flex;
  font-size: 13;
  font-weight: 700;
  letter-spacing: -0.328125px;
  padding: 0px;
  margin-top: -4.5px;
  text-decoration-line: 'underline';
`

const StyledText = styled(LittleContainer)`
  letter-spacing: 0.4px;
  line-height: 16px;
  width: 100% !important;
`

const StyledSegmento = styled(Segmento)`
  border-bottom: 1px solid #f0f0f0;
`

const StyledAvatar = styled(AvatarConvencional)`
  align-items: center;
  background-color: ${(props) => props.color};
  justify-content: center;
  margin-top: 13px !important;
`

export default Transcripcion
