export const htmlDecodeText = (rawText) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(rawText, 'text/html')
  return doc.documentElement.textContent
}
export const blendColors = (colorA, colorB, amount) => {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16))
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16))
  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0')
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0')
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0')
  return `#${r}${g}${b}`
}

export const blendRGBColorArray = (colorArray) => {
  const rgbPattern = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
  if (colorArray.some((color) => !rgbPattern.test(color))) {
    return null
  }
  if (colorArray.length >= 2) {
    return colorArray.splice(1).reduce(
      (finalColor, color) => blendColors(finalColor, color, 0.5),
      colorArray[0],
    )
  }
  if (colorArray.length === 1) {
    return colorArray[0]
  }
  return 'transparent'
}

export const romanToNum = (roman) => {
  if (roman === '') return 0
  if (roman.startsWith('L')) return 50 + romanToNum(roman.substr(1))
  if (roman.startsWith('XL')) return 40 + romanToNum(roman.substr(2))
  if (roman.startsWith('X')) return 10 + romanToNum(roman.substr(1))
  if (roman.startsWith('IX')) return 9 + romanToNum(roman.substr(2))
  if (roman.startsWith('V')) return 5 + romanToNum(roman.substr(1))
  if (roman.startsWith('IV')) return 4 + romanToNum(roman.substr(2))
  if (roman.startsWith('I')) return 1 + romanToNum(roman.substr(1))
  return 0
}

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

export const removeAccents = (s) => s.normalize('NFD').replace(/\p{Diacritic}/gu, '')
