import { Spin } from 'antd'
import styled from 'styled-components'

function LoadingSpinner() {
  return (
    <Wrapper>
      <Spin
        tip="Cargando datos"
        size="large"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export default LoadingSpinner
