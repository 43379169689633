import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { DownloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  Empty, Input, List, Tooltip,
} from 'antd'
import styled from 'styled-components'

import OptionalOutlink from '../../../components/OptionalOutlink'
import { colors, spaces } from '../../../Constants'
import usePagination from '../Pagination'
import { ArticuloCard } from './ArticuloCard'

const { Search } = Input

const ARTICULOS = gql`
query Articulos($sortKey: String, $sortOrder: String,
                $filters: [FilterTerms!], $search: String!,
                $comision: String, $tipoArticulo: String) {
  articulos(
    comision: $comision
    tipoArticulo: $tipoArticulo
    pagination: {
      from: 0
      size: 1000
      sortKey: $sortKey
      sortOrder: $sortOrder
      filters: $filters
      search: $search
  }) {
      pageInfo {
        totalCount
      }
      results {
          nombre
          contenido
          downloadUrl
          titulo
          highlights:highlights_comparador {
              start
              len
              match {
                  documento_id
                  start
                  len
                  file_path
              }
          }
      }
  }
}
`

const DEFAULT_SORT_ORDER = 'descend'
const DEFAULT_SORT_KEY = 'fecha'

export function ArticulosList({
  CardComponent = ArticuloCard,
  leftHeader,
  tipoArticulo = 'articulos',
  tooltipText,
  ispublic,
  findAll,
  defaultDownloadLink = `https://www.chileconvencion.cl/
wp-content/uploads/2022/07/Texto-Definitivo-CPR-2022-Tapas.pdf`,
}) {
  const { comision } = useParams()

  const { hash } = useLocation()

  const [articulos, setArticulos] = useState([])
  const [downloadUrl, setDownloadUrl] = useState(null)

  const paginationConfig = {
    defaultSortKey: DEFAULT_SORT_KEY,
    defaultSortOrder: DEFAULT_SORT_ORDER,
  }

  const {
    getPaginationParams,
    pagination,
    setSearch,
    updatePaginationInfo,
  } = usePagination({ ...paginationConfig })

  const { data, loading, refetch } = useQuery(
    ARTICULOS,
    {
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ articulos: arts }) => {
        updatePaginationInfo(arts.pageInfo)
      },
      variables: {
        ...getPaginationParams(),
        comision: findAll ? '*' : comision,
        tipoArticulo,
      },
    },
  )
  const handleOnSearch = (value) => setSearch(value)

  useEffect(() => {
    handleOnSearch('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.search])

  useEffect(() => {
    const articulosFromBackend = [...(data?.articulos?.results || [])]
    articulosFromBackend?.sort((a, b) => (
      Number(a.nombre.match(/\d+/)) - Number(b.nombre.match(/\d+/))
    ))
    const selectedArticuloKey = decodeURI(hash).replace(/^#/, '')
    setArticulos(articulosFromBackend)
    document.getElementById(selectedArticuloKey)?.scrollIntoView()
  }, [data, hash])

  useEffect(() => {
    const articulo = (articulos.length > 0) && articulos[0]
    setDownloadUrl(articulo?.downloadUrl || defaultDownloadLink)
  }, [articulos, defaultDownloadLink])

  const EmptyResults = (
    <Empty
      description={`No se encontraron artículos
    aprobados en la comisión de ${comision}
    que contengan la palabra "${pagination.search}".`}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )

  const ListHeader = useCallback(
    () => (
      <ArticulosListHeader
        defaultValue={pagination.search}
        downloadUrl={downloadUrl}
        leftHeader={leftHeader}
        onSearch={handleOnSearch}
        tooltipText={tooltipText}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.search, downloadUrl],
  )
  if (loading && !articulos) return null
  return (
    <FlexColumnContainer>
      <StyledList
        dataSource={articulos}
        header={<ListHeader />}
        ispublic={ispublic}
        locale={{ emptyText: EmptyResults }}
        renderItem={(articulo) => (
          <List.Item id={`${articulo.nombre}_${articulo.titulo}`}>
            <CardComponent
              nombre={articulo.nombre}
              contenido={articulo.contenido}
              titulo={articulo.titulo}
            />
          </List.Item>
        )}
        rowKey={({ nombre, titulo }) => [nombre, titulo].join('-')}
      />
    </FlexColumnContainer>
  )
}

function ArticulosListHeader({
  defaultValue, downloadUrl, leftHeader, onSearch, tooltipText,
}) {
  const download = (
    <OptionalOutlink href={downloadUrl}>
      <DownloadButton
        className="download-btn"
        enable={downloadUrl}
      />
    </OptionalOutlink>
  )
  return (
    <FlexHeaderContainer>
      {leftHeader || <span />}
      <Right>
        {tooltipText ? (
          <Tooltip title={tooltipText}>
            {download}
            {' '}
          </Tooltip>
        ) : download}
        <Search
          allowClear
          defaultValue={defaultValue}
          onSearch={onSearch}
          placeholder="Busca por concepto"
        />
      </Right>
    </FlexHeaderContainer>
  )
}

const FlexColumnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

const FlexHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`

const DownloadButton = styled(DownloadOutlined)`
  color: ${(props) => (props.enable ? colors.activeLink : colors.disabledLink)};
  font-size: 18px;
`

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: ${({ ispublic }) => (ispublic ? '100vh' : 'inherit')};
  .ant-spin-nested-loading {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
  }
  .ant-spin-container {
    max-height: ${({ ispublic }) => (ispublic ? '100%' : '65vmin')};
  }

  .ant-empty {
    margin-top: 20%;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${spaces.m};
  .download-btn {
    margin-top: 6px;
  }
  width: max-content;
`

export default ArticulosList
