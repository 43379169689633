import { List, Modal } from 'antd'

const data = [
  {
    description: '"Medio Ambiente"',
    title: 'Para buscar frases exactas, usa comillas dobles:',
  },
  {
    description: '"Medio Ambiente" -Agua',
    title: 'Para filtrar por temas que no quieres buscar, añade un signo menos antes de este:',
  },
  {
    description: '"Medio Ambiente" | "Derechos Humanos"',
    title: 'La búsqueda entregará los resultados que incluyan todos los términos, en caso de querer obtener resultados que incluyan uno u otro término, usar |:',
  },
  {
    description: '("Medio Ambiente" | "Derechos Humanos") -Agua',
    title: 'Se pueden mezclar todas estas opciones para customizar tus búsquedas:',
  },
]

export default function searchExamples() {
  return Modal.info({
    content: (
      <List
        dataSource={data}
        itemLayout="horizontal"
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              description={item.description}
              title={item.title}
            />
          </List.Item>
        )}
      />
    ),
    onOk() {},
    title: 'Ejemplos',
    width: 1000,
  })
}
