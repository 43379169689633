import { gql } from '@apollo/client'

export const LINK_TAG = gql`
  mutation LinkTag($tagId: ID!, $documentId: ID!, $index: String!) {
    agregar_etiqueta_documento(
      input_data: { etiqueta_id: $tagId, documento_id: $documentId, index: $index }
    ) {
      result
      error
      etiqueta {
        id
        nombre
        color
      }
    }
  }
`

export const getLinkTagUpdate = (elementId) => ({
  update(cache, { data }) {
    if (!data.agregar_etiqueta_documento.result) {
      return
    }
    const tag = data.agregar_etiqueta_documento.etiqueta
    const callFragment = gql`
      fragment NewCall on SesionConstituyente {
        id
        etiquetas {
          id
          nombre
          color
        }
      }
    `
    const call = cache.readFragment({
      fragment: callFragment,
      id: `SesionConstituyente:${elementId}`,
    })
    if (call) {
      const alreadyExists = call.etiquetas.filter((t) => t.id === tag.id)
      if (alreadyExists.length > 0) {
        return
      }
      cache.writeFragment({
        data: { ...call, etiquetas: [...call.etiquetas, tag] },
        fragment: callFragment,
        id: `SesionConstituyente:${elementId}`,
      })
    }
  },
})

export const CREATE_TAG = gql`
  mutation CreateTag($name: String!, $color: String!) {
    crear_etiqueta(input_data: { nombre: $name, color: $color }) {
      id
      nombre
      color
    }
  }
`

export const createTagUpdate = {
  update: (cache, { data }) => {
    cache.modify({
      fields: {
        etiquetas: (existingTags = []) => {
          const newTagRef = cache.writeFragment({
            data: data.crear_etiqueta,
            fragment: gql`
              fragment NewTag on Etiqueta {
                id
                nombre
                color
              }
            `,
          })
          return existingTags.concat(newTagRef)
        },
      },
    })
  },
}

export const UNLINK_TAG = gql`
  mutation UnlinkTag($tagId: ID!, $callId: ID!, $index: String!) {
    quitar_etiqueta_documento(
      input_data: { etiqueta_id: $tagId, documento_id: $callId, index: $index }
    ) {
      result
      error
      etiqueta {
        id
      }
      documento_id
    }
  }
`

export const unlinkUpdate = {
  update(cache, { data }) {
    if (!data.quitar_etiqueta_documento.result) {
      return
    }
    const tag = data.quitar_etiqueta_documento.etiqueta
    const docId = data.quitar_etiqueta_documento.documento_id
    const callFragment = gql`
      fragment call on SesionConstituyente {
        id
        etiquetas {
          id
          nombre
          color
        }
      }
    `
    const call = cache.readFragment({
      fragment: callFragment,
      id: `SesionConstituyente:${docId}`,
    })
    if (call) {
      cache.modify({
        fields: {
          etiquetas(existingTags, { readField }) {
            return existingTags.filter((t) => tag.id !== readField('id', t))
          },
        },
        id: cache.identify(call),
      })
    }
  },
}
