import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Popover } from 'antd'
import styled from 'styled-components'

import HighlightedText from 'components/HighlightedText'
import { spaces } from 'Constants'
import {
  parseHighlightedContent,
} from 'views/AuthenticatedSite/Articulos/Comparador/parser'
import { isHighlightActive } from './helpers'
import Matches from './Matches'
import ComparatorModal from './Modal'

const ARTICULOS = gql`
query Articulos($ids: [ArticulosByIds!]) {
  articulos(
    ids: $ids
    pagination: {
      from: 0
      size: 1000
      sortKey: "fecha"
      sortOrder: "desc"
      filters: []
      search: ""
  }) {
      results {
          nombre
          contenido
          documentoId
          articuloId
      }
    }
  }
`

export function ComparadorCard({
  contenido,
  highlights,
  nombre,
  titulo,
}) {
  const [activeHighlights, setActiveHighlights] = useState([])
  const [activeHighlightedContent, setActiveHighlightedContent] = useState([])
  const [modalActive, setModalActive] = useState(false)
  const highlightedContent = parseHighlightedContent({
    contenido,
    highlights,
  })

  const [articulosQuery, { data: comparedArticulos }] = useLazyQuery(
    ARTICULOS,
  )

  const aHighlightedContent = {
    ...highlightedContent,
    highlights: highlightedContent.highlights.filter(
      ({ finish, start }) => isHighlightActive(
        { activeHighlights, finish, start },
      ),
    ),
  }

  useEffect(() => {
    setModalActive(true)
  }, [activeHighlightedContent])

  return (
    <CardWrapper>
      {titulo && <Text>{titulo}</Text>}
      <Text>{nombre}</Text>
      <Content>
        {comparedArticulos && (
        <ComparatorModal
          closeModal={() => setModalActive(false)}
          comparedArticulos={comparedArticulos.articulos.results}
          highlightedContent={activeHighlightedContent}
          nombre={nombre}
          titulo={titulo}
          visible={modalActive}
        />
        )}
        <HighlightedText
          highlightWrapper={{
            component: Popover,
            props: {
              content: <Matches
                activeHighlights={activeHighlights}
                matches={highlightedContent.highlights}
              />,
            },
          }}
          onClick={() => {
            setActiveHighlightedContent(aHighlightedContent)
            const params = {
              variables: {
                ids: aHighlightedContent.highlights.map(({ match }) => ({
                  articuloId: match.file_path.replace('.yml', ''),
                  documentoId: match.documento_id,
                })),
              },
            }
            articulosQuery(params)
          }}
          onHoverChange={
            (newActiveHighlights) => newActiveHighlights.length
              && setActiveHighlights(newActiveHighlights)
          }
          content={highlightedContent.content}
          highlights={highlightedContent.highlights}
        >
          {highlightedContent.content}
        </HighlightedText>
      </Content>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 ${spaces.l};
  width: 100%;
`

const Content = styled.div`
  font-size: calc(1em + 1px);
  color: #4F4F4F;
  width: calc(100% - 10px);
`

const Text = styled.p`
  font-weight: bold;
  font-size: calc(1em + 2px);
`

export default ComparadorCard
