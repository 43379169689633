import styled from 'styled-components'

import { colors, spaces } from 'Constants'
import { ArticulosList } from '../ArticulosList'
import { ComparadorCard } from './ComparadorCard'
import { ComparadorNav as Nav } from './ComparadorNav'
import { Header } from './Header'

function ComparadorDetalle({ ispublic }) {
  return (
    <Wrapper>
      <Header />
      <DetailContainer>
        <Nav
          tipoArticulo="texto_definitivo"
          title="CONTENIDO"
        />
        <Divider />
        <ArticulosList
          CardComponent={ComparadorCard}
          findAll
          ispublic={ispublic}
          leftHeader={<Title>Propuesta de Nueva Constitución</Title>}
          tipoArticulo="texto_definitivo"
          tooltipText="Descargar propuesta nueva constitución"
        />
      </DetailContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: grid;
    grid: min-content 1fr / 1fr;
    height: 100%;
`

const DetailContainer = styled.div`
    display: grid;
    grid-template-columns: auto min-content 1fr;
`

const Divider = styled.div`
    border: 0.1px solid ${colors.backgroundLightGrey};
    min-height: max-content;
`

const Title = styled.h1`
    padding: 0 ${spaces.l};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
`

export default ComparadorDetalle
