import styled from 'styled-components'

import { spaces } from 'Constants'
import { isHighlightActive } from './helpers'
import { Legend, legends } from './Legends'

export default function Matches({ activeHighlights, matches }) {
  return (
    <Wrapper>
      {matches.map(({
        color, finish, match, start,
      }) => {
        if (isHighlightActive({
          activeHighlights,
          finish,
          start,
        })) {
          const { documento_id: documentoId } = match
          return (
            <Legend
              color={color}
              key={`${documentoId}-${start}-${finish}`}
            >
              {legends[documentoId].label}
            </Legend>
          )
        }
        return null
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: grid;
    gap: ${spaces.s};
    cursor: pointer;
`
