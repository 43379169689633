import { FlagsProvider } from 'react-feature-flags'
import { useConfig } from '@unholster/react-use-config'

export default function CustomFlagsProvider({ children }) {
  const formatFlags = (flags) => Object.keys(flags)
    .map((flagName) => ({ isActive: flags[flagName], name: flagName }))

  const config = useConfig()
  const flags = formatFlags(config.flags || {})

  return (
    <FlagsProvider value={flags}>
      {children}
    </FlagsProvider>
  )
}
