module.exports = {
  colors: {
    activeLink: '#2150E8',
    backgroundContent: '#f7f7f7', // Equivalent to #E5E5E5 and 0.3 oppacity
    backgroundDarkGrey: '#E0E0E0',
    backgroundLightGrey: '#EDEDED',
    constitutions: {
      constitucion_1925: '#00AFE3',
      constitucion_actual: '#FF71B1',
      constitucion_mb: '#F8C224',
      leyes_organicas: '#7AD261',
    },
    disabledLink: '#828282',
    menuActiveItem: '#828282',
    menuActiveItemBackground: '#F7F7F7',
    menuSelectedItem: '#2150E8',
    menuSelectedItemBackground: '#2150E80D',
    menuUnselectedItem: '#828282',
    paragraphFont: '#4F4F4F',
    principalFont: '#646464',
    scannerBlue: '#2150E8',
    selectedNav: '#333333',
    speakers: ['#ECF0FF', '#B1BDE4'],
    tagFont: '#C4C4C4',
    unselectedNav: '#BDBDBD',
  },

  spaces: {
    l: '24px',
    m: '12px',
    s: '6px',
    xl: '36px',
    xs: '3px',
    xxl: '48px',
  },

  tags: {
    colors: [
      '#00afe3',
      '#00bdd2',
      '#00c7b2',
      '#01cf8a',
      '#7ad261',
      '#bcd03c',
      '#fac72f',
      '#6d99f3',
      '#b38df0',
      '#eb7dd9',
      '#ff71b1',
      '#ff7380',
      '#ff894b',
      '#ffa600',
    ],
  },
}
