import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Collapse } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'

import { colors, spaces } from 'Constants'
import { romanToNum } from 'utils'
import { Circle } from './Legends'

const { Panel } = Collapse

const naturalSorter = (a, b) => a.localeCompare(
  b,
  undefined,
  { numeric: true, sensitivity: 'base' },
)

const groupArticulosPorComision = (articulos, capitulos) => {
  const articlesByComission = {}
  capitulos.forEach((c) => {
    articlesByComission[c] = articulos
      .filter(({ comision }) => comision === c)
      .sort((a, b) => naturalSorter(a.nombre, b.nombre))
  })
  _(articlesByComission).toPairs().sort(
    (titleA, titleB) => (
      romanToNum(titleA[0].split('.')[0]) - romanToNum(titleB[0].split('.')[0])
    ),
  ).fromPairs()
    .value()

  return articlesByComission
}

const ARTICULOS = gql`
  query Articulos($tipoArticulo: String) {
    articulos(
      tipoArticulo: $tipoArticulo
      pagination: {
        from: 0
        size: 10000
        sortKey: "comision"
        sortOrder: "desc"
    }) {
      results {
        comision
        nombre
        titulo
        highlights:highlights_comparador {
          start
          len
          match {
            documento_id
            start
            len
            file_path
          }
        }
      }
    }
  }
`

export function ComparadorNav({
  tipoArticulo = 'articulos',
  title,
}) {
  const { comision } = useParams()
  const { data } = useQuery(ARTICULOS, {
    variables: {
      tipoArticulo,
    },
  })

  const articulos = data?.articulos?.results.map((art, ind) => ({
    key: `${art.comision.replaceAll(' ', '_')}__${art.nombre.replaceAll(' ', '_')}__${ind}`,
    ...art,
  }))
  const capitulos = Array
    .from(new Set(articulos?.map((articulo) => articulo.comision) || []))
    .sort(
      (titleA, titleB) => (
        romanToNum(titleA.split('.')[0]) - romanToNum(titleB.split('.')[0])
      ),
    )

  const currentComisionIndex = capitulos?.indexOf(comision)
  const groupedByCapitulo = data && groupArticulosPorComision(
    articulos,
    capitulos,
  )

  const articleColors = ({ highlights }) => highlights.map(
    ({ match }) => ({
      color: colors.constitutions[
        match.documento_id.split('articulos_')[1]
      ],
      key: `${match.file_path}__${match.start}`,
    }),
  )

  const capituloColors = ({ arts }) => {
    const colorsByArticle = arts.flatMap(
      ({ highlights }) => articleColors({ highlights }).map(({ color }) => color),
    )
    return Array.from(
      new Set(colorsByArticle),
    )
  }

  return Boolean(data) && (
    <SidePanel>
      {title && <Title>{title}</Title>}
      <StyledCollapse
        accordion
        defaultActiveKey={currentComisionIndex}
      >
        {Object.entries(groupedByCapitulo).map(([c, arts]) => (
          <StyledPanel
            active={c === comision}
            header={<Header colors={capituloColors({ arts })} title={c} />}
            key={c}
            showArrow={false}
          >
            {arts.map(({
              highlights, key, nombre, titulo,
            }) => (
              <li key={key}>
                <ArticuloName
                  articulo={nombre}
                  titulo={(
                    <Circles>
                      { articleColors({ highlights }).map(({ color, key: artKey }) => (
                        <Circle key={artKey} color={color} />
                      ))}
                    </Circles>
                    )}
                  path={`/comparador/${c}#${nombre}_${titulo}`}
                />
              </li>
            ))}
          </StyledPanel>
        ))}
      </StyledCollapse>
    </SidePanel>
  )
}

function Header({ title, colors: headerColors = [] }) {
  return (
    <HeaderWrapper>
      {title}
      <Circles>
        {headerColors.map((c) => (
          <Circle key={`${title.replaceAll(' ', '_')}__${c}`} color={c} />
        ))}
      </Circles>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  gap: ${spaces.m};
  align-items: center;
  justify-content: space-between;
`
const Circles = styled.div`
  display: flex;
  > * {
     margin: 0 -2px;
  }
`

function ArticuloName({ articulo, path, titulo }) {
  const navigate = useNavigate()

  return (
    <ArticuloNameWrapper onClick={() => navigate(path)}>
      <p style={{ marginBottom: 0 }}>{articulo}</p>
      {titulo}
    </ArticuloNameWrapper>
  )
}

const ArticuloNameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spaces.s};
  cursor: pointer;
  margin-bottom: 1em;
`

const Title = styled.span`
  font-size: 12px;
  letter-spacing: 0.065em;
  text-transform: capitalize;
  padding: ${spaces.m} 0;
`

const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s};
`

const StyledCollapse = styled(Collapse)`
  background: white;
  border: none;
  min-width: max-content;
  font-size: calc(1em - 1px);
`

const StyledPanel = styled(Panel)`
  .ant-collapse-content {
    max-height: 25vh;
    overflow-y: scroll;
  }

  &.ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    padding: ${spaces.m} ${spaces.m} ${spaces.m} 0;
  }
`

export default ComparadorNav
