import { Flags } from 'react-feature-flags'
import { Tabs } from 'antd'
import styled from 'styled-components'

import UploadComponent from './UploadComponent'

export default function Reportes() {
  return (
    <Flags authorizedFlags={['ReportUpload']}>
      <StyledContainer>
        <StyledTitle>
          Reportes
        </StyledTitle>
        <StyledTabsContainer
          defaultActiveKey="1"
          items={[{
            children: <UploadComponent />,
            key: '1',
            label: 'Carga',
          }]}
        />
      </StyledContainer>
    </Flags>
  )
}

const StyledContainer = styled.div`
  margin-left: 14px;
`

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32.68px;
`

const StyledTabsContainer = styled(Tabs)`
  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.75px;
    padding: 0 14px;
  }
`
