import { useContext, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  Button, Input, PageHeader, Tabs,
} from 'antd'
import styled from 'styled-components'

import LoadingSpinner from 'components/Spinner'
import { StyledDivider } from 'components/StyledDivider'
import { colors } from 'Constants'
import SearchContext from './SearchContext'
import searchExamples from './SearchExamples'
import SearchResults from './SearchResults'

const SEARCH = gql`
  fragment SearchResults on SearchResponse {
    pageInfo {
      totalCount
    }
    results {
      resourceId
      resourceType
      title
      date
      highlights {
        ... on SegmentHighlight {
          speakerName
          imageUrl
          text
          timecode
        }
      }
    }
  }
  query Search($phrase: String!, $sortKey: String, $from: Int!, $size: Int!) {
    search(
      phrase: $phrase
      pagination: { from: $from, size: $size, sortKey: $sortKey }
    ) {
      sesiones {
        ...SearchResults
      }
    }
  }
`

export default function Search({ children, queryParam, url }) {
  const location = useLocation()
  const searchPhrase = location.pathname === url
    ? new URLSearchParams(location.search).get(queryParam)
    : ''
  const value = useMemo(
    () => ({
      queryParam,
      searchPhrase,
      url,
    }),
    [location],
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

Search.Input = function SearchInput() {
  const navigate = useNavigate()
  const { queryParam, searchPhrase, url } = useContext(SearchContext)

  return (
    <StyledSearchContainer>
      <Input.Search
        defaultValue={searchPhrase}
        key={searchPhrase}
        onSearch={(phrase, event) => {
          navigate(`${url}?${queryParam}=${encodeURIComponent(phrase)}`)
          // eslint-disable-next-line no-param-reassign
          event.target.value = ''
        }}
        placeholder="Ingresa el concepto de tu interés"
      />
      <StyledInfoButton
        icon={
          <InfoOutlined onClick={searchExamples} style={{ fontSize: 12 }} />
        }
        type="primary"
      />
    </StyledSearchContainer>
  )
}

const StyledSearchContainer = styled.div`
  display: inline-flex;
  width: 70%;

  .ant-input:placeholder-shown {
    font-style: italic;
  }

  .anticon-search svg {
    color: ${colors.scannerBlue};
  }
`

const StyledInfoButton = styled(Button)`
  width: 18px;
  height: 18px;
  font-size: 6px;
  border-radius: 50%;
  margin: 6px;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
`

Search.Results = function Results() {
  const { searchPhrase } = useContext(SearchContext)
  const { data, loading } = useQuery(SEARCH, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      from: 0,
      phrase: searchPhrase,
      search: '',
      size: 1000,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  return !data ? null : (
    <>
      <StyledPageHeader
        title={(
          <div>
            Resultados de búsqueda para
            <strong>
              {' '}
              &quot;
              {searchPhrase}
              &quot;
              {' '}
            </strong>
          </div>
        )}
      />
      <StyledDivider />
      <StyledTabsContainer
        defaultActiveKey="1"
        items={[{
          children: <SearchResults dataKey="sesiones" query={SEARCH} />,
          key: '1',
          label: `Coincidencias ${data?.search?.sesiones?.pageInfo?.totalCount || 0}`,
        }]}
      />
    </>
  )
}

const StyledTabsContainer = styled(Tabs)`
  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.75px;
  }
`

const StyledPageHeader = styled(PageHeader)`
  border: none;
  margin-bottom: 0;
  padding-left: 0;

  .ant-page-header-heading-title {
    font-weight: 600 !important;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-family: sans-serif;
    vertical-align: top;
  }
`
