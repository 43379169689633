/* eslint-disable react/jsx-props-no-spreading */
import {
  ApolloClient,
  ApolloProvider as ApolloProviderBase,
  from,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client'

import { useAuth } from 'vendor/authkit'
import { apiUrl } from '..'
import { typeDefs } from './schema'

export function ApolloProvider({ path = 'graphql/', ...props }) {
  const { setAuthenticated } = useAuth()

  const links = [

    onError(({ networkError }) => {
      if (networkError) {
        const errorCode = networkError.result.error.code
        if (errorCode === 401 || errorCode === 403) {
          setAuthenticated(false)
        }
      }
    }),

    new CreateUploadLink({
      credentials: 'include',
      uri: `${apiUrl}/${path}`,
    }),

  ]

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from(links),
    typeDefs,
  })

  return (
    <ApolloProviderBase client={client} {...props} />
  )
}

export default ApolloProvider
