import { Select } from 'antd'
import styled from 'styled-components'

const { Option } = Select

export function SortOptionsDropDown({
  showDefaultValue,
  showOnChange,
  showOptions,
  sortDefaultValue,
  sortOnChange,
  sortOptions,
}) {
  return (
    <DropdownContainer>
      <DropdownSelect>
        <strong>Ordenar:</strong>
        <SortSelect
          bordered={false}
          defaultValue={sortDefaultValue}
          onChange={sortOnChange}
        >
          {
            sortOptions.map(([value, text]) => (
              <Option
                key={value}
                value={value}
              >
                {text}
              </Option>
            ))
          }
        </SortSelect>
      </DropdownSelect>
      <DropdownSelect>
        <strong>Mostrar:</strong>
        <ShowSelect
          bordered={false}
          defaultValue={showDefaultValue}
          onChange={showOnChange}
        >
          {
            showOptions.map((size) => (
              <Option
                key={size}
                value={size}
              >
                {`${size} resultados por página`}
              </Option>
            ))
          }
        </ShowSelect>
      </DropdownSelect>
    </DropdownContainer>
  )
}

export default {
  SortOptionsDropDown,
}

const DropdownSelect = styled.div`
    color: gray;
    padding-left: 8px;
`

const DropdownContainer = styled.div`
    display: flex;
`

const SortSelect = styled(Select)`
  color: gray;
  width: 145px;
  border: none;
`

const ShowSelect = styled(Select)`
  color: gray;
`
