import { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { List, Skeleton } from 'antd'
import styled from 'styled-components'

import Segment from 'components/Segment'
import LoadingSpinner from 'components/Spinner'
import { StyledList } from 'components/StyledList'
import { formatMediumDate } from 'format'
import { capitalize } from 'utils'
import usePagination from '../Pagination'
import { itemRender, orderOptions, pageSizeOptions } from './common'
import SearchContext from './SearchContext'
import { SortOptionsDropDown } from './SortOptionsDropDown'

const SEARCH_PAGE_SIZE_KEY = 'searchPageSize'

export default function SearchResults({ dataKey, EmptyComponent, query }) {
  const defaultPageSize = localStorage.getItem(SEARCH_PAGE_SIZE_KEY)
  const { searchPhrase } = useContext(SearchContext)
  const {
    getPaginationParams,
    pagination,
    setPageNumber,
    setPageSize,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultPageSize: defaultPageSize ? parseInt(defaultPageSize, 10) : 10,
    defaultSortKey: 'fecha',
  })

  const { data, loading, refetch } = useQuery(query, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => updatePaginationInfo(result?.search[dataKey]?.pageInfo),
    variables: {
      phrase: searchPhrase,
      ...getPaginationParams(),
    },
  })

  const skeletonData = Array.from(Array(pagination.pageSize)).map((_x, i) => ({
    highlights: [],
    key: i,
  }))
  const listData = data ? data.search[dataKey].results : []
  const dataSource = loading ? skeletonData : listData

  useEffect(() => {
    setPageNumber(1)
  }, [searchPhrase, pagination.pageSize])

  useEffect(() => {
    refetch({
      phrase: searchPhrase,
      ...getPaginationParams(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, pagination.pageSize, pagination.pageNumber])

  const onSizeChange = (_current, size) => {
    localStorage.setItem(SEARCH_PAGE_SIZE_KEY, size.value)
    setPageSize(size.value)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return !data ? null : (
    <>
      <SortOptionsDropDown
        showDefaultValue={pagination.pageSize}
        showOnChange={onSizeChange}
        showOptions={pageSizeOptions}
        sortDefaultValue={pagination.sort}
        sortOnChange={setSort}
        sortOptions={orderOptions}
      />
      <StyledList
        dataSource={dataSource}
        itemLayout="vertical"
        locale={
          EmptyComponent
            ? {
              emptyText: <EmptyComponent />,
            }
            : undefined
        }
        pagination={{
          current: pagination.pageNumber,
          itemRender,
          onChange: setPageNumber,
          pageSize: pagination.pageSize,
          position: 'top',
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          total: pagination.totalItems,
        }}
        renderItem={(item) => (
          <List.Item>
            <Skeleton active loading={loading}>
              <DateContainer>
                <strong>{capitalize(formatMediumDate(item.date))}</strong>
              </DateContainer>
              <HighlightsContainer>
                <strong>{item.title}</strong>
              </HighlightsContainer>
              <HighlightsContainer>
                {item.highlights.map(
                  ({
                    imageUrl, speakerName, text, timecode,
                  }) => (
                    <Segment
                      imageUrl={imageUrl}
                      key={`${item.resourceId}-${timecode}`}
                      resourceId={item.resourceId}
                      speakerName={speakerName}
                      text={text}
                      timecode={timecode}
                    />
                  ),
                )}
              </HighlightsContainer>
            </Skeleton>
          </List.Item>
        )}
        split={false}
      />
    </>
  )
}

const HighlightsContainer = styled.div`
  border: 1px solid #eee;
  padding: 8px;
`

const DateContainer = styled.div`
  color: gray;
  padding: 8px 0px;
`
