import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Button, Checkbox, DatePicker, Input, List, Space,
} from 'antd'
import styled from 'styled-components'

import PriorityText from 'components/PriorityText'
import LoadingSpinner from 'components/Spinner'
import StyledTable from 'components/StyledTable'
import { TagsContainer } from 'components/Tag'
import TagSelector from 'components/TagSelector'
import dayjs from 'config/dayjs'
import {
  formatFullDate,
  formatHourDate,
  formatSecondsToTimeString,
} from 'format'
import { UNLINK_TAG, unlinkUpdate } from 'mutations/tagMutations'
import usePagination from '../Pagination'

const { Column } = StyledTable
const { RangePicker } = DatePicker
const CheckboxGroup = Checkbox.Group
const operations = ['EBC', 'IOS', 'CPC', 'NS', 'PGL']
const operationsOptions = operations.map((option) => ({ value: option }))
const PAGE_SIZE_KEY = 'callPageSize'

const SESIONES = gql`
  query Search(
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    sesiones(
      pagination: {
        from: $from
        size: $size
        search: $search
        sortKey: $sortKey
        sortOrder: $sortOrder
        filters: $filters
      }
    ) {
      pageInfo {
        totalCount
      }
      results {
        id
        titulo
        fecha
        codigo_operacion
        duracion_seg
        etiquetas {
          id
          nombre
          color
        }
        cloudfront_url
      }
    }
    etiquetas {
      id
      nombre
      color
    }
  }
`

export default function Repositorio() {
  const [dateRange, setDateRange] = useState([])
  const [operationCheckList, setOperationCheckList] = useState([])
  const [tagCheckList, setTagCheckList] = useState([])
  const [sessionSearch, setSessionSearch] = useState('')
  const defaultPageSize = localStorage.getItem(PAGE_SIZE_KEY)
  const location = useLocation()
  const {
    getPaginationParams,
    pagination,
    removeFilters,
    setOrder,
    setPageNumber,
    setPageSize,
    setSearch,
    setSort,
    updateFilters,
    updatePaginationInfo,
  } = usePagination({
    defaultPageSize: defaultPageSize ? parseInt(defaultPageSize, 10) : 50,
    defaultSortKey: 'fecha',
  })
  const { data, loading, refetch } = useQuery(SESIONES, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ sesiones }) => {
      updatePaginationInfo(sesiones.pageInfo)
    },
    variables: getPaginationParams(),
  })
  const [unlinkTag, _unlinkData] = useMutation(UNLINK_TAG, unlinkUpdate)
  const filteredData = data && data.sesiones.results

  const onTableChange = (_pagination, _filters, sorter) => {
    if (sorter) {
      const sortKey = sorter.order ? sorter.columnKey : '_score'
      const sortOrder = sorter.order
      setSort(sortKey)
      setOrder(sortOrder)
    }
  }

  const onSizeChange = (_current, size) => {
    localStorage.setItem(PAGE_SIZE_KEY, size)
    setPageSize(size)
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange([
        dayjs(dateStrings[0], 'DD-MM-YYYY'),
        dayjs(dateStrings[1], 'DD-MM-YYYY'),
      ])
    } else {
      setDateRange([])
    }
  }

  useEffect(() => {
    refetch(getPaginationParams())
  }, [
    pagination.filters,
    pagination.sort,
    pagination.order,
    pagination.pageSize,
    pagination.pageNumber,
  ])
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <StyledContent>
      <StyledTitle>Lista de Audios</StyledTitle>
      <StyledHeader
        dataSource={[]}
        onChange={onTableChange}
        sortDirections={['descend', 'ascend', 'descend']}
      >
        <Column
          key="fecha"
          title="Fecha"
          sorter
          sortOrder={
            pagination.sort === 'fecha' ? pagination.order || 'descend' : null
          }
          filterDropdown={(
            <DropdownDateFilter
              onChange={onRangeChange}
              value={dateRange}
              onClear={() => {
                setDateRange([])
                removeFilters('fecha')
              }}
              onFilter={() => {
                if (dateRange.length > 0) {
                  updateFilters({
                    field: 'fecha',
                    range: {
                      gte: dateRange[0],
                      lte: dateRange[1].endOf('day'),
                    },
                  })
                }
              }}
            />
          )}
          width={125}
        />
        <Column key="hora" title="Hora" width={100} />
        <Column
          key="codigo_operacion"
          title="Operación"
          filterDropdown={(
            <DropDownFilter
              onChange={setOperationCheckList}
              value={operationCheckList}
              options={operationsOptions}
              onClear={() => {
                setOperationCheckList([])
                removeFilters('codigo_operacion')
              }}
              onFilter={() => updateFilters({
                field: 'codigo_operacion',
                terms: operationCheckList.map((item) => item.toLowerCase()),
              })}
            />
          )}
          width={125}
        />
        <Column
          filterDropdown={(
            <DropdownSearch
              onChange={setSessionSearch}
              onClear={() => {
                setSessionSearch('')
                setSearch('')
                removeFilters('llamada')
              }}
              onSearch={() => {
                setSearch(sessionSearch)
                setPageNumber(1)
              }}
              value={sessionSearch}
            />
          )}
          key="titulo"
          title="Audios"
          width={300}
        />
        <Column
          key="duracion_seg"
          sorter
          sortOrder={
            pagination.sort === 'duracion_seg'
              ? pagination.order || 'descend'
              : null
          }
          title="Duración"
          width={100}
        />
        <Column
          key="etiquetas"
          title="Etiquetas"
          filterDropdown={(
            <DropDownFilter
              onChange={setTagCheckList}
              value={tagCheckList}
              options={data.etiquetas.map((tag) => ({
                color: tag.color,
                value: tag.nombre,
              }))}
              onClear={() => {
                setTagCheckList([])
                removeFilters('Etiqueta')
              }}
              onFilter={() => updateFilters({
                field: 'Etiqueta',
                term: 'nombre',
                terms: tagCheckList,
              })}
            />
          )}
        />
        <Column key="menu" title="Preview" width={150} />
      </StyledHeader>
      <StyledList
        bordered
        dataSource={filteredData}
        pagination={{
          current: pagination.pageNumber,
          locale: { items_per_page: '/ página' },
          onChange: setPageNumber,
          onShowSizeChange: onSizeChange,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          total: pagination.totalItems,
        }}
        renderItem={(item) => (
          <List.Item>
            <SessionContainer>
              <SessionDateContainer>
                {formatFullDate(item.fecha)}
              </SessionDateContainer>
              <SessionHourContainer>
                {formatHourDate(item.fecha)}
              </SessionHourContainer>
              <SessionCodigoOperacionContainer>
                {item.codigo_operacion}
              </SessionCodigoOperacionContainer>
              <SessionInfoContainer>
                <Link
                  to={`/llamadas/${item.id}`}
                  state={{ prevPath: location.search }}
                >
                  <PriorityText bold>{item.titulo}</PriorityText>
                </Link>
              </SessionInfoContainer>
              <SessionDurationContainer>
                {formatSecondsToTimeString(item.duracion_seg)}
              </SessionDurationContainer>
              <SessionTagContainer>
                <StyledTagSelector
                  elementId={item.id}
                  tags={data && data.etiquetas}
                  index="sesion"
                />
                <TagsContainer
                  tags={item.etiquetas}
                  onClose={(tagId) => unlinkTag({
                    variables: {
                      callId: item.id,
                      index: 'sesion',
                      tagId,
                    },
                  })}
                />
              </SessionTagContainer>
              <StyledAudioContainer>
                <AudioTableMenu url={item.cloudfront_url} />
              </StyledAudioContainer>
            </SessionContainer>
          </List.Item>
        )}
      />
    </StyledContent>
  )
}

function DropDownFilter({
  onChange, onClear, onFilter, options, value,
}) {
  const optionList = options.map((option) => option.value)
  return (
    <div style={{ padding: 8 }}>
      <StyledCheckboxGroup
        value={value}
        onChange={onChange}
        defaultValue={optionList}
      >
        {options.map((option) => (
          <div key={option.value}>
            <StyledCheckBox color={option.color} value={option.value}>
              {option.value}
            </StyledCheckBox>
          </div>
        ))}
      </StyledCheckboxGroup>
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={onFilter}
          size="small"
          style={{ width: 90 }}
          type="primary"
        >
          Filtrar
        </Button>
        <Button onClick={onClear} size="small" style={{ width: 90 }}>
          Borrar
        </Button>
      </Space>
    </div>
  )
}

function DropdownDateFilter({
  onChange, onClear, onFilter, value,
}) {
  return (
    <div style={{ padding: 8 }}>
      <Space direction="vertical" size={12}>
        <RangePicker onChange={onChange} format="DD-MM-YYYY" value={value} />
        <Space>
          <Button
            icon={<SearchOutlined />}
            onClick={onFilter}
            size="small"
            style={{ width: 90 }}
            type="primary"
          >
            Filtrar
          </Button>
          <Button onClick={onClear} size="small" style={{ width: 90 }}>
            Borrar
          </Button>
        </Space>
      </Space>
    </div>
  )
}

function DropdownSearch({
  onChange, onClear, onSearch, value,
}) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onPressEnter={onSearch}
        placeholder="Buscar..."
        style={{ display: 'block', marginBottom: 8 }}
        value={value}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={onSearch}
          size="small"
          style={{ width: 90 }}
          type="primary"
        >
          Buscar
        </Button>
        <Button onClick={onClear} size="small" style={{ width: 90 }}>
          Borrar
        </Button>
      </Space>
    </div>
  )
}

function createAudio(source, preload = null) {
  const audio = new Audio(source)
  if (preload) {
    audio.preload = preload
  }
  return audio
}

function AudioTableMenu({ className, url }) {
  const [playing, setPlaying] = useState(false)
  const [audio, _setAudio] = useState(createAudio(url, 'none'))

  const start = () => {
    if (!playing) {
      audio.play()
      setPlaying(true)
    }
  }

  const stop = () => {
    if (playing) {
      audio.pause()
      setPlaying(false)
    }
  }
  return (
    <AudioTableMenuContainer className={className}>
      <StyledPlayCircle onClick={start} />
      <StyledPauseCircle onClick={stop} />
    </AudioTableMenuContainer>
  )
}

const AudioTableMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50px;
`

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 24px 24px 0px 24px;
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32.68px;
  margin-left: 14px;
`

const StyledHeader = styled(StyledTable)`
  .ant-table-placeholder,
  .ant-divider {
    display: none;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #fafafa;
  }
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-bottom: 5px;
`

const StyledList = styled(List)`
  min-width: 400px;
  height: 85%;
  .ant-spin-nested-loading {
    overflow: overlay;
    height: 89%;
  }
  .ant-list-item {
    padding: 12px 0px;
  }
  .ant-list-pagination {
    margin-bottom: 0px;
  }
`

const SessionContainer = styled.div`
  display: flex;
  flex: 1;
`

const SessionTagContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 16px;
  gap: 12px;
  align-items: center;
`

const StyledTagSelector = styled(TagSelector)`
  margin-top: 3px;
  align-self: start;
`

const SessionDateContainer = styled.div`
  display: flex;
  max-width: 125px;
  min-width: 125px;
  padding: 0 16px;
`

const SessionHourContainer = styled.div`
  display: flex;
  max-width: 100px;
  min-width: 100px;
  padding: 0 16px;
`

const SessionDurationContainer = styled.div`
  display: flex;
  text-align: center;
  max-width: 100px;
  min-width: 100px;
  padding: 0 16px;
`

const SessionInfoContainer = styled.div`
  justify-content: center;
  max-width: 300px;
  min-width: 300px;
  padding: 0 16px;
  font-weight: 700;
`

const SessionCodigoOperacionContainer = styled.div`
  text-align: center;
  max-width: 125px;
  min-width: 125px;
`

const StyledCheckBox = styled(Checkbox)`
  color: ${(props) => (props.color ? props.color : 'black')};
`

const StyledAudioContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding: 0px 16px;
  max-width: 150px;
  min-width: 150px;
`

const StyledPlayCircle = styled(PlayCircleOutlined)`
  color: black;
  font-size: 20px;
  margin-right: 4px;
`

const StyledPauseCircle = styled(PauseCircleOutlined)`
  color: black;
  font-size: 20px;
`
