import { Table } from 'antd'
import styled from 'styled-components'

export default styled(Table)`
  .ant-table-cell,
  th.ant-table-column-sort {
    background: white;
  }

  th {
    font-size: 0.8em;
    vertical-align: bottom;
    overflow: hidden;
    line-height: 18px;

    &::before {
      width: 0;
    }
  }

  tr {
    margin-top: 4px;
  }
  .ant-table-filter-column {
    display: inline-flex;
  }
  .ant-table-column-sorter {
    margin-left: 10px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
`
