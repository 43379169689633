import styled from 'styled-components'

import { colors, spaces } from 'Constants'

export const legends = {
  constitucion_1925: {
    color: colors.constitutions.constitucion_1925,
    label: 'Constitución 1925',
  },
  constitucion_actual: {
    color: colors.constitutions.constitucion_actual,
    label: 'Constitución actual',
  },
  constitucion_mb: {
    color: colors.constitutions.constitucion_mb,
    label: 'Propuesta de reforma constitucional Gobierno M. Bachelet',
  },
  leyes_organicas: {
    color: colors.constitutions.leyes_organicas,
    label: 'Leyes Orgánicas Constitucionales',
  },
}

export function Legends() {
  return (
    <Wrapper>
      {Object.values(legends).map(
        ({ color, label }) => (
          <Legend color={color} key={label}>
            {label}
          </Legend>
        ),
      )}
    </Wrapper>
  )
}

export function Legend({
  active = true, children, color, onClick,
}) {
  return (
    <LegendWrapper
      active={active}
      onClick={onClick}
    >
      <Circle color={color} />
      {children}
    </LegendWrapper>
  )
}

export const Circle = styled.div`
    height: 11px;
    width: 11px;
    background-color: ${({ color }) => color || 'black'};
    border-radius: 50%;
    display: inline-block;
`

const LegendWrapper = styled.div`
    display: grid;
    grid: 1fr / min-content 1fr;
    gap: ${spaces.s};
    font-weight: 700;
    font-size: 11px;
    opacity: ${({ active }) => (active ? '100%' : '20%')};
    cursor: ${({ onClick }) => onClick && 'pointer'};

    ${Circle} {
        margin-top: 4px;
    }
`

const Wrapper = styled.div`
    display: flex;
    gap: ${spaces.m};
    padding: ${spaces.l} 0;
    @media only screen and (max-width: 1265px) {
        flex-wrap: wrap;
    }
    ${LegendWrapper} {
        font-weight: 400;
    }
`
