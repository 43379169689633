import styled from 'styled-components'

import { parseContent } from 'views/AuthenticatedSite/Articulos/parser'

export function ArticuloCard({
  contenido,
  nombre,
  titulo,
}) {
  const currentContent = parseContent(contenido)

  return (
    <CardWrapper>
      {titulo && <span className="title">{titulo}</span>}
      <p className="name">{nombre}</p>
      <div className="content">
        {currentContent.map((c) => <p key={c}>{c}</p>)}
      </div>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  .name, .title {
    font-weight: 700;
    font-size: calc(1em + 2px);
    color: #000000;
    line-height: 22px;
  }

  .content {
    font-size: calc(1em + 1px);
    width: 98%;
  }
`

export default ArticuloCard
