import { Route, Routes } from 'react-router-dom'
import { Layout } from 'antd'
import styled from 'styled-components'

import { colors, spaces } from 'Constants'
import Reportes from './Reportes'
import Search from './Search'
import Sesiones from './Sesiones'
import Sesion from './Sesiones/Sesion'
import Sidebar from './Sidebar'
import {
  LogoutButton,
} from './TopNavigation/Buttons'
import { StyledTopBar } from './TopNavigation/TopBarNavigation'

export default function AuthenticatedSite() {
  const { Content, Sider } = Layout

  return (
    <StyledLayout>
      <Sider theme="light" width="240">
        <Sidebar />
      </Sider>
      <Content>
        <Search queryParam="q" url="/search">
          <StyledTopBar>
            <Search.Input />
            <StyledTopBar.Separator />
            <LogoutButton />
          </StyledTopBar>
          <Sheet>
            <Routes>
              <Route element={<Sesiones />} path="/" />
              <Route element={<Sesion />} path="/llamadas/:sesionId" />
              <Route element={<Sesiones />} path="/llamadas" />
              <Route element={<Search.Results />} path="/search" />
              <Route element={<Reportes />} path="/reportes" />
              <Route element={<Sesiones />} />
            </Routes>
          </Sheet>
        </Search>
      </Content>
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;

  & > .ant-layout-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
  }

  .ant-layout,
  .ant-layout-footer {
    background-color: white;
  }

  a {
    color: ${colors.scannerBlue};
  }
`

const Sheet = styled.div`
  flex: 1;
  position: relative;
  background: white;
  margin: ${spaces.l};
  padding: ${spaces.l};
  border-radius: 10px;
`
