/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { Flags } from 'react-feature-flags'
import ReactPlayer from 'react-player'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Divider, Layout, Spin } from 'antd'
import styled from 'styled-components'

import PageHeader from 'components/StyledPageHeader'
import { TagsContainer } from 'components/Tag'
import TagSelector from 'components/TagSelector'
import { colors } from 'Constants'
import { UNLINK_TAG, unlinkUpdate } from 'mutations/tagMutations'
import Transcripcion, { toSeconds } from './Transcripcion'

const { Content } = Layout

const PlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin: 0 0 33px 0 !important;
`

const StyledPlayer = styled(ReactPlayer)`
  height: 50px !important;
  width: 93% !important;
  margin: 0 !important;
`

const StyledSpin = styled(Spin)`
  margin-top: 15px;
  width: 100%;
`

const TRANSCRIPCION = gql`
  query getTranscripciones(
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    transcripciones(
      pagination: {
        from: $from
        size: $size
        search: $search
        sortKey: $sortKey
        sortOrder: $sortOrder
        filters: $filters
      }
    ) {
      pageInfo {
        totalCount
      }
      results {
        id
        texto
        tiempo_duracion
        tiempo_inicio
        processed_on
        sesion
        tiempo_fin
        tiempo_inicio
        duracion_seg
        hablante
      }
    }
  }
`

const SESION = gql`
  query getSesion(
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    sesiones(
      pagination: {
        from: $from
        size: $size
        search: $search
        sortKey: $sortKey
        sortOrder: $sortOrder
        filters: $filters
      }
    ) {
      pageInfo {
        totalCount
      }
      results {
        id
        titulo
        fecha
        cloudfront_url
        youtube_url
        etiquetas {
          id
          nombre
          color
        }
      }
    }
    etiquetas {
      id
      nombre
      color
    }
  }
`

const isFromYoutube = (url) => url.startsWith('https://www.youtube.com')

function DataCell({ className, data, name }) {
  return (
    <div className={className}>
      <StyledCellTitle>{name}</StyledCellTitle>
      <StyledCell>{data}</StyledCell>
    </div>
  )
}

export default function Sesion() {
  const navigate = useNavigate()
  const location = useLocation()
  const { sesionId } = useParams()

  const match = /^#(\d+:\d\d:\d\d)$/.exec(location.hash)
  const startingSeconds = match ? toSeconds(match[1]) : 0

  const [playedSeconds, setPlayedSeconds] = useState(startingSeconds)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const dataTranscripcion = useQuery(TRANSCRIPCION, {
    variables: {
      filters: [{ field: 'sesion', term: sesionId }],
      from: 0,
      search: '',
      size: 10000,
      sortKey: 'fecha',
      sortOrder: 'desc',
    },
  })
  const sesionVariables = {
    filters: [{ field: 'id', term: sesionId }],
    from: 0,
    search: '',
    size: 1,
    sortKey: 'fecha',
    sortOrder: 'desc',
  }
  const dataSesion = useQuery(SESION, {
    variables: sesionVariables,
  })

  const [unlinkTag, _unlinkData] = useMutation(UNLINK_TAG, unlinkUpdate)

  const playerRef = useRef()
  const currentPlayer = playerRef.current
  const transcripcionRef = useRef()

  const goto = (seconds) => {
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, 'seconds')
      const { url } = playerRef.current.props
      const isFromBeginning = seconds === 0
      const shouldPlay = isLoaded && !isFromBeginning
      if (isFromYoutube(url)) {
        const internalPlayer = playerRef.current.getInternalPlayer()
        internalPlayer.playVideo()
      }
      setIsPlaying(shouldPlay)
    }
    if (transcripcionRef.current) {
      transcripcionRef.current.follow(seconds)
    }
  }

  useEffect(() => {
    if (currentPlayer) {
      goto(startingSeconds)
    }
  }, [currentPlayer, startingSeconds, isLoaded])

  const loading = !(dataTranscripcion.data && dataSesion.data)
  if (loading) {
    return <StyledSpin tip="Cargando..." />
  }

  const {
    data: {
      transcripciones: { pageInfo, results: resultsTranscripciones },
    },
  } = dataTranscripcion
  const {
    data: {
      sesiones: {
        results: [resultsSesiones],
      },
    },
  } = dataSesion

  return (
    <StyledContent>
      <StyledSticky>
        <StyledPageHeader
          backIcon={<LeftOutlined style={{ color: colors.scannerBlue }} />}
          onBack={() => navigate(`/llamadas${location.state ? location.state.prevPath : ''}`)}
          title={(
            <StyledTagContainer>
              <StyledTagSelector
                elementId={resultsSesiones.id}
                tags={dataSesion.data.etiquetas}
                index="sesion"
              />
              <TagsContainer
                tags={resultsSesiones.etiquetas}
                onClose={(tagId) => unlinkTag({
                  variables: {
                    callId: resultsSesiones.id,
                    index: 'sesion',
                    tagId,
                  },
                })}
              />
            </StyledTagContainer>
          )}
        />
        <TitleContainer>
          <StyledTitle>{resultsSesiones.titulo}</StyledTitle>
        </TitleContainer>

        <Flags authorizedFlags={['CallDetails']}>
          <StyledInfoContainer>
            <StyledDataCell name="Objetivo" data={resultsSesiones.objetivo} />
            <StyledDataCell name="Nº Origen" data={resultsSesiones.n_origen} />
            <StyledDataCell name="Nº Destino" data={resultsSesiones.n_inicio} />
            <StyledDataCell name="Fecha Inicio" data={resultsSesiones.fecha_inicio} />
            <StyledDataCell name="Fecha Atención" data={resultsSesiones.fecha_atencion} />
            <StyledDataCell name="Fecha Término" data={resultsSesiones.fecha_termino} />
            <StyledDataCell name="Dirección" data={resultsSesiones.direccion} />
          </StyledInfoContainer>
        </Flags>

        <PlayerWrapper>
          <StyledPlayer
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            controls
            onProgress={(progress) => {
              if (progress.loaded !== 0) setPlayedSeconds(progress.playedSeconds)
            }}
            onReady={() => setIsLoaded(true)}
            playing={isPlaying}
            progressInterval={200}
            ref={playerRef}
            url={resultsSesiones.cloudfront_url}
          />
        </PlayerWrapper>
        {pageInfo?.totalCount > 0 && <Transcripcion.Header />}
        <Divider style={{ margin: 0 }} />
      </StyledSticky>
      <Transcripcion
        highlightSeconds={playedSeconds}
        onSeek={goto}
        ref={transcripcionRef}
        transcripcion={resultsTranscripciones}
      />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
`

const Sticky = styled.div`
  top: 0;
  position: sticky;
  background: white;
`

const StyledSticky = styled(Sticky)`
  z-index: 1;
`

const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
`

const StyledTitle = styled.strong`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
`

const TitleContainer = styled.div`
  margin-left: 55px;
  margin-bottom: 20px;
`

const StyledTagSelector = styled(TagSelector)`
  margin: auto 0px;
`

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 0px;
  padding-bottom: 10px;
`
const StyledInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 45px;
`

const StyledCellTitle = styled.div`
  font-weight: 600;
`

const StyledCell = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 16px;
  min-width: 127px;
  height: 40px;
  border-radius: 4px;
  color: #595959;
`

const StyledDataCell = styled(DataCell)`
  margin-right: 6px;
`
