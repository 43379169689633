import styled from 'styled-components'

import { Legends } from './Legends'

export function Header() {
  return (
    <Wrapper>
      <Title>
        Comparador Constitucional
      </Title>
      <Legends />
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: grid;
    grid: min-content / 1fr;
`
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
`

export default Header
