/* eslint-disable react/no-danger */
import { Link } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Comment, Typography } from 'antd'
import styled from 'styled-components'

import AvatarConvencional from 'components/AvatarConvencional'

const { Text } = Typography

function Segment({
  imageUrl, resourceId, speakerName, text, timecode,
}) {
  return (
    <ListComment
      author={(
        <div>
          <Text type="primary">
            <span
              className="speaker"
              dangerouslySetInnerHTML={{ __html: speakerName || '' }}
            />
          </Text>
          {' '}
          -
          {' '}
          <StyledLink to={`/llamadas/${resourceId}#${timecode}`}>
            {timecode}
          </StyledLink>
        </div>
      )}
      avatar={imageUrl
        ? (
          <AvatarConvencional
            src={imageUrl}
          />
        )
        : (
          <AvatarConvencional
            icon={<UserOutlined />}
          />
        )}
      content={(
        <StyledText type="secondary">
          <span
            className="text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </StyledText>
      )}
    />
  )
}

const ListComment = styled(Comment)`
  .ant-comment-avatar {
    cursor: default;
  }

  .ant-comment-content-detail {
    line-height: 22px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
  }
`

const StyledLink = styled(Link)`
  line-height: 22px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
`

const StyledText = styled(Text)`
  font-style: italic;
`

export default Segment
