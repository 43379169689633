import React from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ConfigProvider } from '@unholster/react-use-config'
import { ConfigProvider as ANTDConfigProvider } from 'antd'
import esES from 'antd/es/locale/es_ES'
import dayJsEs from 'dayjs/locale/es-mx'

import { capitalize } from 'utils'
import packageInfo from '../package.json'
import App from './App'
import FlagsProvider from './providers/FlagsProvider'
import reportWebVitals from './reportWebVitals' // eslint-disable-line

if (process.env.NODE_ENV !== 'development') {
  const GACode = process.env.REACT_APP_GA_ID || ' '
  ReactGA.initialize(GACode)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: `${packageInfo.name}@${packageInfo.version}`,
    tracesSampleRate: 0.1,
  })
}
// https://github.com/ant-design/ant-design/issues/26699
esES.DatePicker.lang.shortWeekDays = dayJsEs.weekdaysShort.map(
  (weekday) => capitalize(weekday).slice(0, -1),
)
esES.DatePicker.lang.shortMonths = dayJsEs.monthsShort.map(capitalize)

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ConfigProvider configPath={process.env.REACT_APP_CONFIG_PATH}>
      <FlagsProvider>
        <ANTDConfigProvider locale={esES}>
          <App />
        </ANTDConfigProvider>
      </FlagsProvider>
    </ConfigProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
