import { PageHeader } from 'antd'
import styled from 'styled-components'

export default styled(PageHeader)`
  margin-bottom: 16px;
  .ant-page-header-heading-title {
    font-weight: 550 !important;
    font-style: normal;
    font-size: 21px;
    font-family: sans-serif;
    line-height: 29px;
    color: #000000;
    vertical-align: top;
  }
`
