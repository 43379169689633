import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_SORT_KEY = '_score'
const DEFAULT_SORT_ORDER = 'descend'
const orderOptions = { ascend: 'asc', descend: 'desc' }

export default function usePagination({
  defaultTotalItems = 0,
  defaultPageNumber = 1,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultSortKey = DEFAULT_SORT_KEY,
  defaultSortOrder = DEFAULT_SORT_ORDER,
  defaultFilters = [],
  defaultSearch = '',

} = {}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalItems, setTotalItems] = useState(defaultTotalItems)
  const [pageNumber, setPageNumber] = useState(Number(searchParams.get('page')) || defaultPageNumber)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sort, setSort] = useState(searchParams.get('sort') || defaultSortKey)
  const [order, setOrder] = useState(searchParams.get('order') || defaultSortOrder)
  const [filters, setFilters] = useState(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : defaultFilters)
  const [search, setSearch] = useState(defaultSearch)

  const urlParams = {
    filters: filters ? JSON.stringify(filters) : '',
    order,
    page: String(pageNumber),
    sort,
  }

  const updatePaginationInfo = ({ totalCount }) => setTotalItems(totalCount)

  const getPaginationParams = ({ from, size } = {}) => {
    const startOfPage = (pageNumber - 1) * pageSize
    return {
      filters,
      from: from || startOfPage,
      search,
      size: size || pageSize,
      sortKey: sort || defaultSortKey,
      sortOrder: orderOptions[order || defaultSortOrder],
    }
  }

  const updateFilters = (newFilter) => {
    const oldFilters = filters.filter((f) => f.field !== newFilter.field)
    setFilters([...oldFilters, newFilter])
    setPageNumber(1)
  }

  const removeFilters = (name) => {
    setFilters(filters.filter((f) => f.field !== name))
    setPageNumber(1)
  }

  useEffect(() => {
    setSearchParams({
      ...[...searchParams].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      ...urlParams,
    })
  }, [filters, order, pageNumber, pageSize, sort, search])

  useEffect(() => {
    const newParams = [...searchParams].reduce((acc, [key, value]) => (
      Object.keys(urlParams).includes(key) ? {
        ...acc,
        [key]: value,
      } : acc), {})
    if (!_.isEqual(urlParams, newParams)) {
      setFilters(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : defaultFilters)
      setOrder(defaultSortOrder)
      setPageNumber(Number(searchParams.get('page')) || defaultPageNumber)
      setSort(defaultSortKey)
    }
  }, [searchParams])

  return {
    getPaginationParams,
    pagination: {
      filters,
      order,
      pageNumber,
      pageSize,
      search,
      sort,
      totalItems,
    },
    removeFilters,
    setFilters,
    setOrder,
    setPageNumber,
    setPageSize,
    setSearch,
    setSort,
    updateFilters,
    updatePaginationInfo,
  }
}
