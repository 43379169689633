export const isHighlightActive = (
  { activeHighlights, finish, start },
) => activeHighlights.some(
  (activeHighlight) => (
    finish === activeHighlight.finish
        && start === activeHighlight.start
  ),
)

export default isHighlightActive
