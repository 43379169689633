import { useCallback, useEffect, useState } from 'react'
import { TagOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Dropdown, Input } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'

import constants from 'Constants'
import {
  CREATE_TAG, createTagUpdate, getLinkTagUpdate, LINK_TAG,
} from 'mutations/tagMutations'
import { removeAccents } from 'utils'

function TagItem({ color, nombre }) {
  return <StyledTagLabel color={color}>{nombre}</StyledTagLabel>
}

const filterTags = (toFilterTags, search) => toFilterTags
  .filter((tag) => removeAccents(tag.nombre)
    .toLowerCase()
    .includes(removeAccents(search).toLowerCase()))

const formatTags = (newTags) => newTags.map((tag) => ({ key: tag.id, label: TagItem(tag), tag }))

const getNextColor = (colors, tags) => {
  const frecuency = colors.map(
    (color) => tags.filter((tag) => tag.color === color).length,
  )
  const minimum = Math.min(...frecuency)
  const minIndex = colors.indexOf(minimum)
  if (minIndex >= 0) {
    return colors[minIndex]
  }
  return _.sample(colors)
}

export default function TagSelector({
  className,
  elementId,
  index,
  tags = [],
}) {
  const [items, setItems] = useState(formatTags(tags))
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [linkTag, _linkTagData] = useMutation(LINK_TAG, getLinkTagUpdate(elementId))
  const [createTag, _createTagData] = useMutation(CREATE_TAG, {
    ...createTagUpdate,
    onCompleted: (data) => linkTag({
      variables: {
        documentId: elementId,
        index,
        tagId: data.crear_etiqueta.id,
      },
    }),
  })

  useEffect(() => {
    setItems(formatTags(filterTags(tags, search)))
  }, [search, tags])

  const onSearch = useCallback((input) => setSearch(input.target.value.trim()), [setSearch])

  const onPressEnter = useCallback(() => {
    if (!search) {
      return
    }
    const name = search
    const color = getNextColor(constants.tags.colors, tags)

    const alreadyExists = items.filter(
      (item) => removeAccents(item.tag.nombre).toLowerCase() === removeAccents(name).toLowerCase(),
    )

    if (alreadyExists.length > 0) {
      linkTag({
        variables: {
          documentId: elementId,
          index,
          tagId: alreadyExists[0].tag.id,
        },
      })
    } else {
      createTag({
        variables: { color, name },
      })
    }
    setOpen(false)
    setSearch('')
  }, [search, items, linkTag, createTag, elementId, setOpen, setSearch])

  const renderMenu = useCallback((menu) => (
    <StyledDropdownContainer>
      <Input
        placeholder="Busca o crea una etiqueta"
        onChange={onSearch}
        onPressEnter={onPressEnter}
      />
      <StyledDropdownItemsContainer>{menu}</StyledDropdownItemsContainer>
    </StyledDropdownContainer>
  ), [onSearch, onPressEnter])

  return (
    <StyledContainer className={className}>
      <Dropdown
        menu={{
          items,
          onClick: ({ key }) => linkTag({
            variables: {
              documentId: elementId,
              index,
              tagId: key,
            },
          }),
        }}
        trigger={['click']}
        autoFocus
        destroyPopupOnHide
        dropdownRender={renderMenu}
        open={open}
        onOpenChange={setOpen}
      >
        <StyledTagIcon />
      </Dropdown>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
`

const StyledTagIcon = styled(TagOutlined)`
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  font-size: 16px;
`

const StyledTagLabel = styled.span`
  color: ${(props) => props.color};
`

const StyledDropdownContainer = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
`

const StyledDropdownItemsContainer = styled.div`
  background: white;
  max-height: 150px;
  overflow-y: auto;

  .ant-dropdown-menu {
    padding: 0px;
  }
`
