import { TopBar } from '@unholster/seisveinte'
import styled from 'styled-components'

import { spaces } from 'Constants'

const { BasicTopBar } = TopBar

export const StyledTopBar = styled(BasicTopBar)`
  padding: ${spaces.l} 0 ${spaces.l} 22px;
  margin-left: 2px;
  background-color: white;
`

StyledTopBar.Separator = BasicTopBar.Separator

export default {
  StyledTopBar,
}
