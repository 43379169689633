import { useState } from 'react'
import {
  ExportOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

import Logout from 'views/Logout'

const StyledButton = styled(Button)`
    color: grey;
`

function ProfileButton({ children }) {
  return (
    <StyledButton
      icon={<UserOutlined />}
      type="text"
    >
      {' '}
      {children || 'Perfil'}
      {' '}

    </StyledButton>
  )
}

function LogoutButton() {
  const [logout, setLogout] = useState(false)
  return (
    <StyledButton
      icon={<ExportOutlined />}
      onClick={() => { setLogout(true) }}
      type="text"
    >
      {' '}
      Salir
      {logout && <Logout />}
    </StyledButton>
  )
}

export { LogoutButton, ProfileButton }
