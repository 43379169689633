import { gql } from '@apollo/client'

export const typeDefs = gql`
type Query {
  convencional(id: String!): Convencional
  convencionales: [Convencional]!
  votaciones: [Votacion]!
  search(phrase: String!): [SearchResult]!
  sesion(id: String!): SesionConstituyente!
  sesiones: [SesionConstituyente]!
}

type SearchResult {
  resourceId: String
  resourceType: String!
  title: String!
  highlights: [SearchHighlight]!
  videoUrl: String
}

type SearchHighlight {
  text: String!
  timecode: String!
  speaker: String!
}

type SesionConstituyente {
  id: ID!
  portada: Archivo!
  transcripcion: Transcripcion!
  fecha: String!
  titulo: String!
  descripcion: String!
  videoUrl: String!
}

type Transcripcion {
  archivo: Archivo
  segmentos: [Segmento]!
}

type Archivo {
  url: String
  contentType: String!
}

type Segmento {
  speaker: String!
  start: String!
  end: String!
  texto: String!
}

type Convencional {
  id: ID!
  fotoUrl: String
  rut: String!
  edad: Int!
  nombreCompleto: String!
  nombres: String!
  apellidoPaterno: String!
  apellidoMaterno: String!
  sexo: String!
  fechaDeNacimiento: String!
  profesion: String!
  region: String!
  comuna: String!
  pacto: Pacto
  partido: String
  distrito: Int
  puebloIndigena: String
  avaluoFiscal: Float
  superficieDomicilio: Float
  linkedInUrl: String
  linkedInTrabajos: String
  linkedInEmpresas: String
  tweets: [Tweet]!
}

type Tweet {
  id: ID!
  createdAt: String!
  userScreenName: String!
  text: String!
  favoriteCount: Int!
  retweetCount: Int!
  ingestDate: String!
}

type Pacto {
    key: String!
    fotoUrl: String!
    nombre: String!
}

type Votacion {
  id: ID!
  titulo: String
  tema: String
  resultado: String!
  patrocinadores: [String]!
  fecha: String!
  votos: [Voto]!
}

type Voto {
  opcion: String!
  elector: String!
}

type ArticulosByIds {
  documentoId: String!
  articuloId: String!
}
`

export default typeDefs
