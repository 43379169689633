import { gql } from '@apollo/client'

export const UPLOAD_REPORTS = gql`
  mutation UploadReport($fileList: [Upload!]!) {
    subir_reportes(
      input_data: $fileList
    ) {
      result
    }
  }
`
export default UPLOAD_REPORTS
