import { useEffect } from 'react'

import { baseUrl } from 'config'

export default function Login() {
  useEffect(() => {
    window.location.href = `${baseUrl}/auth/auth`
  })
  return null
}
