import { formatLocale } from 'd3-format'

import dayjs from 'config/dayjs'

const locale = formatLocale({
  currency: ['$', ''],
  decimal: ',',
  grouping: [3],
  thousands: '.',
})

export function formatRut(value) {
  const [num, dv] = value.split('-')
  const format = locale.format(',d')
  return `${format(num)}-${dv.toUpperCase()}`
}

export function formatDateWithFormat(value, format) {
  return dayjs(value).format(format)
}

export function formatLongDate(value) {
  return formatDateWithFormat(value, 'D MMM YYYY[,] LT')
}

export function formatShortDate(value) {
  return formatDateWithFormat(value, 'DD MMM')
}

export function formatMediumDate(value) {
  return formatDateWithFormat(value, 'MMMM YYYY')
}

export function formatNationalDate(value) {
  return formatDateWithFormat(value, 'DD/MM/YYYY')
}

export function formatFullDate(value) {
  return formatDateWithFormat(value, 'DD MMM YYYY')
}

export function formatHourDate(value) {
  return formatDateWithFormat(value, 'hh:mm:ss')
}

export const formatSecondsToTime = (totalSeconds = 0) => {
  const totalMinutes = Math.floor(totalSeconds / 60)

  const seconds = totalSeconds % 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return { hours, minutes, seconds }
}

export const formatSecondsToTimeString = (totalSeconds) => {
  const { hours, minutes, seconds } = formatSecondsToTime(totalSeconds)
  const hoursStr = String(hours).padStart(2, '0')
  const minutesStr = String(minutes).padStart(2, '0')
  const secondsStr = String(seconds).padStart(2, '0')
  return `${hoursStr}:${minutesStr}:${secondsStr}`
}
