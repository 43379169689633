import { useState } from 'react'
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Upload } from 'antd'
import styled from 'styled-components'

import { colors } from 'Constants'
import { UPLOAD_REPORTS } from 'mutations/fileMutations'

const { Dragger } = Upload

const REPORT_EXTENSIONS = ['.xlsx']

export default function UploadComponent() {
  const [fileList, setFileList] = useState([])
  const [uploadFileList, _uploadData] = useMutation(UPLOAD_REPORTS, {
    onCompleted: () => {
      setFileList([])
    },
  })

  const handleUpload = () => {
    uploadFileList({ variables: { fileList } })
  }

  return (
    <>
      <div>
        Sube un archivo que contenga los datos actualizados de los reportes.
        Los campos correo electrónico y número telefónico se utilizan para
        identificar al usuario y no serán actualizados.
      </div>
      <StyledDraggerContainer>
        <Dragger
          accept="xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={() => false}
          onChange={(info) => setFileList(
            info.fileList
              .filter((file) => REPORT_EXTENSIONS.some((ext) => file.name.endsWith(ext))),
          )}
          fileList={fileList}
          multiple
        >
          <StyledCloudUpload />
          <StyledText>Arrastra y suelta aquí tu archivo</StyledText>
          <StyledText>ó</StyledText>
          <StyledFindButton>Buscar un archivo</StyledFindButton>
          <StyledText>Formato aceptado .xlsx</StyledText>
        </Dragger>
        <StyledUploadButton
          disabled={fileList.length === 0}
          onClick={handleUpload}
        >
          <UploadOutlined />
          Cargar
        </StyledUploadButton>
      </StyledDraggerContainer>
    </>
  )
}

const StyledDraggerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  margin: 16px 0;
`

const StyledCloudUpload = styled(CloudUploadOutlined)`
  color: #9499AE;
  font-size: 40px;
`
const StyledText = styled.div`
  color: #9499AE;
  margin: 12px 0;
`

const StyledFindButton = styled(Button)`
  border-color: ${colors.scannerBlue};
  border-radius: 4px;
  color: ${colors.scannerBlue};
`

const StyledUploadButton = styled(Button)`
  border-radius: 4px;
  margin-left: auto;
  margin-top: 16px;
`
