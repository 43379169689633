import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { colors } from '../../../Constants'

export const pageSizeOptions = [10, 20, 50, 100]
export const orderOptions = Object.entries(
  {
    _score: 'Más relevantes',
    fecha: 'Más recientes',
  },
)

export function itemRender(current, type, originalElement) {
  const style = {
    border: '1px solid',
    borderRadius: '6px',
    color: colors.scannerBlue,
    fontSize: '16px',
    padding: '7px',
  }
  if (type === 'prev') {
    return (
      <LeftOutlined
        style={style}
      />
    )
  }
  if (type === 'next') {
    return (
      <RightOutlined
        style={style}
      />
    )
  }
  return originalElement
}
